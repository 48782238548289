import React, { Component, Fragment } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import ProtectedRoute from '../route/ProtectedRoute';
import Home from '../../home/Home';
import Account from '../../account/Account';
import Profile from '../../account/profile/Profile';
import Password from '../../account/password/Password';
import Renew from '../../account/renew/Renew';
import Upgrade from '../../account/upgrade/Upgrade';
import Orders from '../../account/orders/Orders';
import Order from '../../account/orders/Order';
import Transactions from '../../account/transactions/Transactions';
import SignIn from '../../account/signin/SignIn';
import Forgot from '../../account/forgot/Forgot';
import Reset from '../../account/forgot/Reset';
import SignOut from '../../account/signout/SignOut';
import SignUp from '../../account/signup/SignUp';
import Partner from '../../account/signup/Partner';
import Service from '../../service/Service';
import Car from '../../car/Car';
import Offer from '../../offer/Offer';
import Voucher from '../../voucher/Voucher';
import Page from '../../page/Page';
import Search from '../../search/Search';
import AllCategories from '../../categories/AllCategories';
import Cart from '../../cart/Cart';
import HTTP404 from '../error/HTTP404';
import Categories from '../categories/Categories';
import Modal from 'react-modal';
import Localized from '../i18n/Localized';
import Loader from 'react-loaders';
import Config from '../../../config/Config';
import ReactLoading from 'react-loading';
import MapSearch from '../../search/MapSearch';
import EstoreProduct from '../../estore/EstoreProduct';
import Chat from '../../chat/Chat';
import PrepaidCard from '../../account/prepaidCard/PrepaidCard';
import PrepaidCardTransactions from '../../account/prepaidCard/PrepaidCardTransactions';
import PrepaidCardTransfer from '../../account/prepaidCard/PrepaidCardTransfer';
import Payment from '../../payment/Payment';
import UsedCar from '../../usedCar/UsedCar';
import VoucherPackage from '../../voucherPackage/VoucherPackage';
import UAEPASSUnlink from '../../account/UAEPASSUnlink';
import Cancel from '../../account/cancel/Cancel';

const Main = inject('rootStore')(withRouter(observer(
	class Main extends Component {

		constructor(props) {
			super(props);

			this.state = {
				shadowTop: false,
				shadowBottom: false
			};

			this.favoriteCategoriesModalRef = React.createRef();

			this.loadData = this.loadData.bind(this);
			this.handleCategoryToggle = this.handleCategoryToggle.bind(this);
			this.handleSaveFavoriteCategories = this.handleSaveFavoriteCategories.bind(this);

			this.handleScroll = this.handleScroll.bind(this);
		}

		componentDidMount() {
			window.addEventListener('resize', this.handleScroll);
		}

		componentDidUpdate() {
			this.handleScroll();
		}

		componentWillUnmount() {
			window.removeEventListener('resize', this.handleScroll);
		}

		loadData() {
			const { categoryStore, userStore } = this.props.rootStore;

			categoryStore.loadAllCategories();
			userStore.loadFavoriteCategories();
		}
		
		handleCategoryToggle(id) {
			const { userStore } = this.props.rootStore;

			userStore.toggleFavoriteCategory(id);
		}

		handleSaveFavoriteCategories(e) {
			e.preventDefault();

			const { userStore } = this.props.rootStore;

			userStore.persistFavoriteCategories();
		}

		handleScroll() {
			if (this.favoriteCategoriesModalRef.current) {
				const shadowTop = this.favoriteCategoriesModalRef.current.scrollTop > 0;
				const shadowBottom = this.favoriteCategoriesModalRef.current.offsetHeight + this.favoriteCategoriesModalRef.current.scrollTop < this.favoriteCategoriesModalRef.current.scrollHeight;

				if (shadowTop !== this.state.shadowTop || shadowBottom !== this.state.shadowBottom) {
					this.setState({ shadowTop, shadowBottom });
				}
			}
		}

		handleHideMessageModal = () => {
			const { commonStore } = this.props.rootStore;

			commonStore.setModalVisible(false);
		};

		render() {
			const { commonStore } = this.props.rootStore;
			const showCategories = false; /*this.props.location.pathname !== '/categories';*/
			const { locale, translateMessage, modalVisible, modalType, modalTitle, modalMessage } = commonStore;
			const { sessionTimeoutWarningVisible, favoriteCategoriesVisible, favoriteCategories, loading : userLoading, authenticated, me } = this.props.rootStore.userStore;
			const { loading, allCategories : categories } = this.props.rootStore.categoryStore;
			const { mapSearchOpen } = this.props.rootStore.searchStore;

			// Rework service routes to be dynamic
			return (
				<main className="main">
					<Switch>
						<Route exact path="/" component={Home} />
						<ProtectedRoute exact path="/account" component={Account} />
						<ProtectedRoute exact path="/account/profile" component={Profile} />
						<ProtectedRoute exact path="/account/password" component={Password} />
						<ProtectedRoute exact path="/account/renew" /*component={Renew}*/ render={() => <Redirect to="/page/message" />} />
						<ProtectedRoute exact path="/account/upgrade" /*component={Upgrade}*/ render={() => <Redirect to="/page/message" />} />
						<ProtectedRoute exact path="/account/orders" component={Orders} />
						<ProtectedRoute exact path="/account/orders/archive" render={props => <Orders {...props} archive={true} />} />
						<ProtectedRoute exact path="/account/orders/view/:id" component={Order} />

						{!Config.HIDE_DYNAMIC_OFFERS && (
							<ProtectedRoute exact path="/account/transactions" component={Transactions} />
						)}
						
						{false && (
							<Fragment>
								<ProtectedRoute exact path="/account/prepaid-card" component={PrepaidCard} prepaidContext={true} />
								<ProtectedRoute exact path="/account/prepaid-card/transactions" component={PrepaidCardTransactions} prepaidContext={true} />
								<ProtectedRoute exact path="/account/prepaid-card/transfer" component={PrepaidCardTransfer} prepaidContext={true} />
							</Fragment>
						)}

						<Route exact path="/account/signin" component={SignIn} />
						<Route exact path="/account/forgot" component={Forgot} />
						<Route exact path="/account/reset/:token" component={Reset} />
						<ProtectedRoute exact path="/account/signout" component={SignOut} />
						<Route exact path="/account/signup" /*component={SignUp}*/ render={() => <Redirect to="/page/message" />} />
						<Route exact path="/account/signup/partner" component={Partner} />
						<Route exact path="/services/view/:slug" component={Service} />

						<Route exact path="/services/view/long-term-car-lease/:carSlug" component={Car} />
						<Route exact path="/services/view/fazaa-used-cars/:usedCarSlug" component={UsedCar} />
						
						<Route exact path="/offers/view/:slug" component={Offer} />
						<ProtectedRoute exact path="/extra-offers/view/:slug" render={props => <Offer {...props} dynamic={true} />} />
						<Route exact path="/vouchers/view/:slug" component={Voucher} />
						<Route exact path="/voucher-packages/view/:slug" component={VoucherPackage} />
						<Route exact path="/page/(.*)" component={Page} />
						<Route exact path="/search" component={Search} />
						<Route exact path="/categories" component={AllCategories} />
						<Route exact path="/store/product/:slug" component={EstoreProduct} />
						<Route exact path="/cart" component={Cart} />
						<Route exact path="/chat" component={Chat} />
						<Route exact path="/payment/:id" component={Payment} />

						{/* UAE PASS callback URL leads to SignIn component after UAE PASS feature refactor */}
						<Route exact path="/uae-sso" component={SignIn} uaePass={true} />

						<Route exact path="/account/unlink/:number/:code" component={UAEPASSUnlink} />
						<ProtectedRoute exact path="/account/cancel" component={Cancel} />

						<Route component={HTTP404} />
					</Switch>

					{showCategories ? (
						<Categories />
					) : null}

					<Modal
						isOpen={sessionTimeoutWarningVisible}
						overlayClassName="modal"
						className="modal__content"
						ariaHideApp={false}
					>
						<h6 className="modal__title">
							<Localized code="main.title.sessionAboutToExpire" />
						</h6>

						<p className="modal__text">
							<Localized code="main.text.sessionAboutToExpire" />
						</p>

						<div className="modal__buttons">
							<button className="button button--small button--gold" onClick={() => window.location.reload()}>
								<Localized code="main.button.refreshSession" />
							</button>
						</div>
					</Modal>

					<Modal
						isOpen={modalVisible}
						overlayClassName="modal"
						className={`modal__content modal__content--message ${modalType && `modal__content--message-${modalType.toString()}`}`}
						ariaHideApp={false}
					>
						<h6 className="modal__title">{modalTitle}</h6>

						<p className="modal__text">{modalMessage}</p>

						<div className="modal__buttons">
							<button className="button button--small button--gold" onClick={this.handleHideMessageModal}>
								<Localized code="main.button.okay" />
							</button>
						</div>
					</Modal>

					<Modal
						isOpen={favoriteCategoriesVisible}
						overlayClassName="modal"
						className="modal__content modal__content--favorite-categories"
						ariaHideApp={false}
						onAfterOpen={this.loadData}
					>
						<h6 className="modal__title modal__title--center">
							<Localized code="main.title.favoriteCategories" />
						</h6>

						<p className="modal__text modal__text--center">
							<Localized code="main.text.favoriteCategories" />
						</p>

						<div className={"modal__favorite-categories" + (this.state.shadowTop ? " modal__favorite-categories--shadow-top": "") + (this.state.shadowBottom ? " modal__favorite-categories--shadow-bottom" : "")}>
							<div ref={this.favoriteCategoriesModalRef} className="favorite-categories" onScroll={this.handleScroll}>
								{!loading ? (
									categories.map(category => (
										<div key={category.id} className={"favorite-categories__category " + (favoriteCategories.includes(category.id) ? " favorite-categories__category--selected" : "")} onClick={() => this.handleCategoryToggle(category.id)}>
											<div className="favorite-categories__item">
												<div className="favorite-categories__image-wrap">
													<img className="favorite-categories__image" src={`${Config.UPLOADS_ENDPOINT}${category.categoryImageUri}`} alt={category.localData[locale].name} title={category.localData[locale].name} />
												</div>

												<h4 className="favorite-categories__name">{category.localData[locale].name}</h4>
											</div>
										</div>
									))
								) : (
									<div className="favorite-categories__loading">
										<Loader type="line-scale-pulse-out-rapid" color="#002652" />
									</div>
								)}
							</div>
						</div>

						<div className="modal__buttons modal__buttons--center">
							<button disabled={loading || userLoading || favoriteCategories.length !== 3} className="button button--small button--gold form__button--has-loading" onClick={this.handleSaveFavoriteCategories}>
								{userLoading ? (
									<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
								) : null}
								<Localized code="main.button.saveFavoriteCategories" />
							</button>
						</div>
					</Modal>

					{mapSearchOpen ? (
						<MapSearch locale={locale} />
					) : null}
				</main>
			);
		}

	}
)));

export default Main;
